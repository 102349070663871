// Node Modules
import {
  useEffect, useState
} from 'react';

// components
import MainSlide from './main-slide/MainSlide';
import OurLocations from './our-locations/OurLocations';
import OurVideos from './our-videos/OurVideos';
import Cards from '@/components/global-components/cards/Cards';
import Reviews from '@/components/global-components/reviews/Reviews';
import ProgramTypes from './program-types/ProgramTypes';
import Alliances from './alliances/Alliances';
import Container from '@/components/global-components/Container';
import Button from '@/components/global-components/Button';

// Scripts
import { getCardsPostsContent } from '@/graphql/shared/posts';

import { fetchPosts } from '@/services/posts';

// Themes
import theme from '@/themes/index';

const HomeBody = ({
  content,
  isMobile,
  isDesktop,
}) => {
  const [recentPosts, setRecentPosts] = useState([]);

  async function getRecentPosts() {
    const response = await fetchPosts(3, null, '', '', '')
      .catch(function (error) {
        console.log('ERROR: ', error);
      });
    setRecentPosts(response.data.posts)
  }


  const programCards = content.programs.map(program => {
    return {
      card: {
        title: `${program.node.title}`,
        description: program.node.summary,
        url: '/location',
        image: program.node.programImage,
      },
    }
  });

  useEffect(() => {
    getRecentPosts();
  }, []);

  return (
    <>
      <section className='main-slide'>
        <MainSlide content={content.mainSlide} isDesktop={isDesktop} />
      </section>
      <section className='top-programs'>
        <Cards
          isMobile={isMobile}
          isDesktop={isDesktop}
          content={{
            title: 'Top Programs',
            subTitle: 'There are many reasons & ways to start learning Spanish at your own rhythm',
            cards: programCards,
          }}
        />
       
      </section>
      <section className='program-types'>
        <div className='figure program-types-figure'>
          <img src={'/assets/images/Asset_1.png'}/>
        </div>
        <Container>
          <ProgramTypes content={content.programTypes} />
        </Container>
      </section>
      <section className='our-locations'>
        <OurLocations
          isMobile={isMobile}
          isDesktop={isDesktop}
          content={content.ourLocations}
        />
      </section>
      <section className='reviews'>
        <div className='figure reviews-figure'>
          <img src={'/assets/images/Asset_2.png'}/>
        </div>
        <Reviews content={content.reviews} isMobile={isMobile} />
      </section>
      <section className='our-videos'>
        <Container>
          <OurVideos isDesktop={isDesktop} content={content.ourVideos} />
        </Container>
      </section>
      <section className='the-pura-vida-experience'>
        <div className='figure pura-vida-figure'>
          <img src={'/assets/images/Asset_3.png'}/>
        </div>
        <Container>
          <Cards
            isMobile={isMobile}
            isDesktop={isDesktop}
            content={{
              ...content.thePuraVidaExperience,
              cards: getCardsPostsContent(recentPosts.slice(0, 3)),
            }}
          />
        </Container>
      </section>
      <hr />
      <section className='alliances'>
        <Container>
          <Alliances content={content.alliances} />
        </Container>
      </section>
      <style jsx>{`
        hr {
          width: 100%;
          max-width: 87.5rem;
          margin: 0 auto;
        }

        section.reviews,
        section.our-videos {
          background-color: ${theme.backgroundColors.secondary};
        }
  
        section.our-locations,
        section.program-types,
        section.top-programs,
        section.alliances,
        section.the-pura-vida-experience {
          margin: 3rem 2rem;
        }

        section.reviews,
        section.our-videos {
          padding: 3rem 2rem;
        }

        section.reviews,
        section.program-types,
        .the-pura-vida-experience {
          position: relative;
        }

        section .figure {
          flex-flow: row-reverse;
          padding-right: 3%;
          position: absolute;
          display: flex;
          width: 100%;
          margin: 0 auto;
        }

        section.program-types .program-types-figure {
          z-index: -1;
          top: -8rem;
        }

        section.reviews .reviews-figure {
          top: -8rem;
          width: 375px;
          right: 0;
        }

        section.the-pura-vida-experience .pura-vida-figure {
          flex-flow: row;
          top: -13rem;
          padding-left: 3%;
        }
  
        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          section.reviews {
            padding-right: 2rem;
            padding-left: 2rem;
          }

          section.the-pura-vida-experience .pura-vida-figure {
            flex-flow: row;
            top: -17rem;
            padding-left: 3%;
          }
        }
  
        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {

          section.our-locations,
          section.program-types,
          section.top-programs,
          section.alliances,
          section.the-pura-vida-experience {
            margin: 3rem 4.5rem;
          }

          section.reviews,
          section.our-videos {
            padding: 3rem 4.5rem;
          }

          
        }
      `}</style>
      <style global jsx>{`
        section.the-pura-vida-experience .container .header-container p {
          margin-block-start: 0;
          margin-block-end: 0;
        }  

        section.top-programs .primary {
          margin: auto
        }

        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          section.the-pura-vida-experience .container .header-container p {
            font-size: 1.5rem !important;
            line-height: 2.25rem !important;
          }
        }
      `}</style>
    </>
  )
}

export default HomeBody;
